@import "../xl/body-xl";
@import "experience-lg";

@media (min-height: 700px) {
  .evp-header {
    height: 45%;
    padding-top: 5rem;
  }

  .evp-main {
    height: 55%;
  }
}
