.evp-gallery {
  &__col {
    &_sq {
      height: 200px;
    }
    &_v {
      height: 280px;
    }
  }
}
