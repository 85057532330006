.evp-profile {
  width: 33rem;

  &__avatar {
    $height: 11.25rem;
    $width: 9.375rem;

    height: $height;
    position: relative;
    width: $width;

    &:before {
      background-size: $height * 0.923;
      height: $height;
      top: -.8rem;
      width: $width;
    }
  }

  &__info {
    background-size: 100%;
    height: 2.5rem;
    width: 11rem;
  }
}
