:root {
  --bs-root-font-size: 16px;
}

.evp-profile {
  width: 33rem;
}

.evp-profile__avatar {
  width: 9.375rem;
  height: 11.25rem;
  position: relative;
}

.evp-profile__avatar:before {
  background-size: 10.3838rem;
  width: 9.375rem;
  height: 11.25rem;
  top: -.8rem;
}

.evp-profile__info {
  background-size: 100%;
  width: 11rem;
  height: 2.5rem;
}

.evp-gallery__col_sq {
  height: 200px;
}

.evp-gallery__col_v {
  height: 280px;
}

.evp-exp__col_ignet .evp-exp__box {
  padding-bottom: 63.5%;
}

@media (height >= 700px) {
  .evp-header {
    height: 45%;
    padding-top: 5rem;
  }

  .evp-main {
    height: 55%;
  }
}
/*# sourceMappingURL=index.b12685a3.css.map */
